<template>
	<main>
		<header>
			<a href="/">
				<img :src="require('@/assets/img/hdc-logo.svg')" alt="Band image">
			</a>
		</header>

		<OnNow v-if="null !== on_now" :band="on_now"/>

		<section class="bands-wrapper">
			<h2>Upcoming bands</h2>

			<ul class="bands">
				<li v-for="(band, index) in bands" :key="index">
					<Band :band="band" />
				</li>
			</ul>
		</section>
	</main>
</template>

<script>
import bands from '@/assets/json/bands.json';
import moment from 'moment';
import _ from 'lodash';
import OnNow from '@/components/OnNow.vue';
import Band from '@/components/Band.vue';

export default {
	name: 'Home',

	data() {
		return {
			bands: [],
			on_now: null
		}
	},

	components: {
		OnNow,
		Band
	},

	mounted() {
		if (Object.values(bands).length > 0) {
			Object.values(bands).forEach(band => {
				let default_format = 'DD-MM-YYYY HH:mm';
				let slot = moment(band.slot, default_format);

				// collect the start moment for comparison.
				band.slot_start = moment(band.slot, default_format);

				// slot andend time.
				band.slot_length = 2400;

				if ('headliner' === band.slot_type) {
					band.slot_length = 3600;
				}
				
				band.slot_end_orig = moment(slot, default_format).add(band.slot_length, 's');
				band.slot_end = moment(slot, default_format).add(band.slot_length, 's').format(default_format);

				// work out who is "on now".
				if (true === moment().isBetween(moment(band.slot_start, default_format), moment(band.slot_end, default_format))) {
					this.on_now = band;
				}

				band.on_now = moment().isBetween(moment(band.slot_start, default_format), moment(band.slot_end, default_format));

				// sanitization stuff.
				band.slot_from_now = slot.fromNow();

				// format the slot.
				band.slot = slot.format('dddd Do MMMM YYYY @ HH:mm');
				band.slot_timestamp = slot.unix();

				if (true !== band.on_now) {
					this.bands.push(band);
				}
			});

			// order by timestamp.
			this.bands = _.orderBy(this.bands, 'slot_timestamp', 'asc');
		}
	}
}
</script>

<style lang="scss">
	body {
		background-image: url('~@/assets/img/hdc-background.png');
		background-size: contain;
		background-repeat: repeat;
		background-position: center;
		background-color: #000;
		color: #fff;
	}

	img {
		width: 100%;
		height: auto;
		max-width: 50ch;
	}

	/* Box sizing rules */
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	/* Remove default margin */
	body,
	h1,
	h2,
	h3,
	h4,
	p,
	figure,
	blockquote,
	dl,
	dd {
		margin: 0;
	}

	/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
	ul[role='list'],
	ol[role='list'] {
		list-style: none;
	}

	/* Set core root defaults */
	html:focus-within {
		scroll-behavior: smooth;
	}

	/* Set core body defaults */
	body {
		min-height: 100vh;
		text-rendering: optimizeSpeed;
		line-height: 1.6;
		letter-spacing: 0.01rem;
	}

	/* A elements that don't have a class get default styles */
	a:not([class]) {
		text-decoration-skip-ink: auto;
	}

	/* Make images easier to work with */
	img,
	picture {
		max-width: 100%;
		width: 100%;
		display: block;
		height: auto;
	}

	/* Standardise SVGs */
	svg {
		width: 1rem;
		height: 1rem;
		display: inline-block;
		vertical-align: middle;
		fill: currentColor;
	}

	/* Inherit fonts for inputs and buttons */
	input,
	button,
	textarea,
	select {
		font: inherit;
	}

	/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
	@media (prefers-reduced-motion: reduce) {
		html:focus-within {
			scroll-behavior: auto;
		}

		*,
		*::before,
		*::after {
			animation-duration: 0.01ms !important;
			animation-iteration-count: 1 !important;
			transition-duration: 0.01ms !important;
			scroll-behavior: auto !important;
		}
	}

	header {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		padding: 1rem;
		text-align: center;
		width: 100%;

		&:before {
			content: '';
			position: absolute;
			z-index: 0;
			top: -50%;
			left: 0;
			width: 100%;
			height: 200%;
			background: linear-gradient(180deg, rgba(0, 0, 0, .9) 0, rgba(0, 0, 0, 0) 100% );
		}

		a {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			z-index: 1;
		}

		img {
			width: 100%;
			height: 80px;
			display: block;
			margin-inline: auto;
		}
	}

	main {
		max-width: 90vw;
		margin-inline: auto;
	}
</style>

<style scoped lang="scss">
	.bands {
		margin-left: 0;
		list-style: none;
		padding: 0;
	}
</style>