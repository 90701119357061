<template>
	<article class="band" v-if="true === is_mounted">
		<div class="band-image">
			<img :src="require('@/assets/' + band.image)" alt="Band image">
		</div>

		<div class="band-info">
			<p>{{band.name}}</p>

			<p>{{band.slot}}—{{band.slot_end_orig.format('HH:mm')}}</p>

			<router-link :to="{name: 'Band', params: { slug: band.slug }}">View band</router-link>
		</div>
	</article>
</template>

<script>
	export default {
		name: "OnNow",

		props: {
			band: Object
		},

		data() {
			return {
				is_mounted: false
			}
		},

		mounted() {
			if (null !== this.band) {
				this.is_mounted = true;
			}
		}
	}
</script>

<style lang="scss">
	.band {
		text-align: center;
		position: relative; 
	}

	.band-image {
		position: relative; 

		img {
			aspect-ratio: 1/1;
			object-fit: cover;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, .9) 0, rgba(0, 0, 0, 0) 100% );
		}
	}

	.band-info {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 1rem;
	}
</style>