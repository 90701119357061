<template>
	<section v-if="true === is_mounted">
		<article class="band">
			<div class="band-image">
				<img :src="require('@/assets/' + band.image)" alt="Band image">
			</div>

			<div class="band-info">
				<p>{{band.name}}</p>

				<p>{{band.slot}}—{{band.slot_end_orig.format('HH:mm')}}</p>

				<router-link :to="{name: 'Band', params: { slug: band.slug }}">View band</router-link>
			</div>
		</article>
	</section>
</template>

<script>
	export default {
		name: "OnNow",

		props: {
			band: Object
		},

		data() {
			return {
				is_mounted: false
			}
		},

		mounted() {
			if (null !== this.band) {
				this.is_mounted = true;
			}
		}
	}
</script>

<style scoped lang="scss">
	.band {
		margin-left: -5vw;
		margin-right: -5vw;
	}

	.band-image {
		img {
			aspect-ratio: 1/1.5;
			max-height: 90vh;
		}
	}
</style>