import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
{
	path: '/',
	name: 'Home',
	component: Home
},
{
	path: '/band/:slug',
	name: 'Band',
	component: () => import(/* webpackChunkName: "band" */ '../views/About.vue')
}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
